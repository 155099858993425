import React from 'react';
//import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
//import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { notify } from 'shared/lib/notification';
import { Button, Flex, MaterialInput, Typography } from 'shared/_ui';
//import { getRestore } from 'shared/consts/routes';
//import { LoginValues, useLogin } from '../../api/login';
import { useSendOtp, Email } from 'shared/api/otpApi'
//import { validationScheme } from '../../lib/validationScheme';

import styles from './styles.module.scss';
import { getRouteOtpCheck } from 'shared/consts';
import i18next from 'i18next';

type LoginFormProps = {
  authProviders?: () => React.ReactNode;
};

export const LoginForm: React.FC<LoginFormProps> = ({ authProviders }) => {
  
  const { t } = useTranslation();
  const [otplogin, { isLoading }] = useSendOtp();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<Email>({
    mode: 'onSubmit',
    defaultValues: { email: '', languageCode: i18next.language }
  });

  const useOnSubmit = async (values: Email) => {
    localStorage.setItem('user-email',values.email)

    if(localStorage.getItem('resend-time') == null) {
      localStorage.setItem('resend-time', new Date().getTime().toString())
    }
    if( Number(localStorage.getItem('resend-time')) > new Date().getTime() ) {
      notify.error(`${t('authentication.otp.wait')}${Math.ceil(( Number(localStorage.getItem('resend-time')) - new Date().getTime() ) / 1000)}${t('authentication.otp.seconds')}`);
      return
    }
    await otplogin(values)
      .unwrap()
      .then(() => {
        localStorage.setItem( 'resend-time', (new Date().getTime() + 60000).toString() )
        window.location.replace(getRouteOtpCheck());
      })
      .catch(() => {
        notify.error(t('authentication.login.error'));
      });
  };

  return (
    <Flex direction="column" spacing={20}>
      <form onSubmit={handleSubmit(useOnSubmit)}>
        <Typography.Text type="secondary" className='fix'>
          {t('authentication.registration.otp')}
        </Typography.Text>
        <Flex direction="column" spacing={20} className={styles.wrapper}>
          <div className={styles.field}>
            <MaterialInput placeholder="Email" {...register('email')} />
            {errors.email && (
              <Typography.Text type="danger">
                {t(
                  errors.email?.message
                    ? errors.email?.message
                    : 'shared.error.base'
                )}
              </Typography.Text>
            )}
          </div>
          
          <Button loading={isLoading} type="primary">
            {t('authentication.login.loginButton')}
          </Button>
        </Flex>
      </form>
      {authProviders?.()}
    </Flex>
  );
};
