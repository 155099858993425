export const MAP_COUNTRY_TO_ICON: Record<string, React.ReactNode> = {
  KZ: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3893_16497)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#338AF3"
        />
        <path
          d="M25.0435 16.1738H6.95654C6.95654 17.4225 8.04411 18.4347 9.29267 18.4347H9.21742C9.21742 19.6834 10.2296 20.6956 11.4783 20.6956C11.4783 21.9443 12.4905 22.9565 13.7392 22.9565H18.2609C19.5096 22.9565 20.5218 21.9443 20.5218 20.6956C21.7705 20.6956 22.7827 19.6834 22.7827 18.4347H22.7074C23.9559 18.4347 25.0435 17.4225 25.0435 16.1738Z"
          fill="#FFDA44"
        />
        <path
          d="M22.2609 13.2168C22.2609 16.6745 19.4578 19.4777 16 19.4777C12.5422 19.4777 9.73914 16.6745 9.73914 13.2168"
          fill="#338AF3"
        />
        <path
          d="M20.7821 13.2172L18.8281 14.1363L19.8686 16.0288L17.7468 15.6229L17.4779 17.7664L16 16.1898L14.522 17.7664L14.2531 15.6229L12.1313 16.0287L13.1718 14.1363L11.2178 13.2172L13.1718 12.298L12.1313 10.4056L14.2531 10.8115L14.522 8.66797L16 10.2445L17.478 8.66797L17.7468 10.8115L19.8687 10.4056L18.8281 12.2981L20.7821 13.2172Z"
          fill="#FFDA44"
        />
      </g>
      <defs>
        <clipPath id="clip0_3893_16497">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  DE: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3893_16551)">
        <path
          d="M0.995117 21.5631C3.2558 27.6559 9.12049 31.9979 15.9999 31.9979C22.8794 31.9979 28.7441 27.6559 31.0047 21.5631L15.9999 20.1719L0.995117 21.5631Z"
          fill="#FFDA44"
        />
        <path
          d="M15.9999 0C9.12049 0 3.2558 4.342 0.995117 10.4348L15.9999 11.8261L31.0047 10.4347C28.7441 4.342 22.8794 0 15.9999 0Z"
          fill="black"
        />
        <path
          d="M0.995188 10.4355C0.352063 12.1689 0 14.0436 0 16.0007C0 17.9579 0.352063 19.8326 0.995188 21.5659H31.0049C31.648 19.8326 32 17.9579 32 16.0007C32 14.0436 31.648 12.1689 31.0048 10.4355H0.995188Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3893_16551">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  US: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3893_16555)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F0F0F0"
        />
        <path
          d="M15.3043 16.0001H31.9999C31.9999 14.556 31.8074 13.157 31.4488 11.8262H15.3043V16.0001Z"
          fill="#D80027"
        />
        <path
          d="M15.3043 7.65245H29.6516C28.6721 6.0542 27.4198 4.64152 25.9597 3.47852H15.3043V7.65245Z"
          fill="#D80027"
        />
        <path
          d="M16 31.9997C19.7656 31.9997 23.2267 30.6982 25.9598 28.5215H6.04028C8.77341 30.6982 12.2345 31.9997 16 31.9997Z"
          fill="#D80027"
        />
        <path
          d="M2.34834 24.3478H29.6516C30.4379 23.0647 31.0477 21.6622 31.4488 20.1738H0.551147C0.95221 21.6622 1.56202 23.0647 2.34834 24.3478Z"
          fill="#D80027"
        />
        <path
          d="M7.4115 2.49863H8.86956L7.51331 3.48394L8.03137 5.07825L6.67519 4.09294L5.319 5.07825L5.7665 3.70094C4.57237 4.69562 3.52575 5.861 2.66325 7.1595H3.13044L2.26712 7.78669C2.13262 8.01106 2.00362 8.239 1.88 8.47031L2.29225 9.73913L1.52313 9.18031C1.33194 9.58537 1.15706 9.99956 0.999875 10.4224L1.45406 11.8204H3.13044L1.77419 12.8057L2.29225 14.4L0.936063 13.4147L0.123687 14.0049C0.042375 14.6586 0 15.3243 0 16H16C16 7.1635 16 6.12175 16 0C12.8393 0 9.89281 0.916875 7.4115 2.49863ZM8.03137 14.4L6.67519 13.4147L5.319 14.4L5.83706 12.8057L4.48081 11.8204H6.15719L6.67519 10.2261L7.19319 11.8204H8.86956L7.51331 12.8057L8.03137 14.4ZM7.51331 8.14481L8.03137 9.73913L6.67519 8.75381L5.319 9.73913L5.83706 8.14481L4.48081 7.1595H6.15719L6.67519 5.56519L7.19319 7.1595H8.86956L7.51331 8.14481ZM13.7705 14.4L12.4143 13.4147L11.0581 14.4L11.5762 12.8057L10.2199 11.8204H11.8963L12.4143 10.2261L12.9323 11.8204H14.6087L13.2524 12.8057L13.7705 14.4ZM13.2524 8.14481L13.7705 9.73913L12.4143 8.75381L11.0581 9.73913L11.5762 8.14481L10.2199 7.1595H11.8963L12.4143 5.56519L12.9323 7.1595H14.6087L13.2524 8.14481ZM13.2524 3.48394L13.7705 5.07825L12.4143 4.09294L11.0581 5.07825L11.5762 3.48394L10.2199 2.49863H11.8963L12.4143 0.904312L12.9323 2.49863H14.6087L13.2524 3.48394Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3893_16555">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  RU: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3893_16563)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F0F0F0"
        />
        <path
          d="M31.0048 21.5659C31.648 19.8326 32 17.9579 32 16.0007C32 14.0436 31.648 12.1689 31.0048 10.4355H0.995188C0.352063 12.1689 0 14.0436 0 16.0007C0 17.9579 0.352063 19.8326 0.995188 21.5659L16 22.9572L31.0048 21.5659Z"
          fill="#0052B4"
        />
        <path
          d="M15.9999 31.9993C22.8794 31.9993 28.7441 27.6573 31.0047 21.5645H0.995117C3.2558 27.6573 9.12049 31.9993 15.9999 31.9993Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3893_16563">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  GB: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3893_16952)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F0F0F0"
        />
        <path
          d="M3.30709 6.25781C2.05028 7.893 1.10259 9.77769 0.550781 11.8251H8.87441L3.30709 6.25781Z"
          fill="#0052B4"
        />
        <path
          d="M31.4485 11.8251C30.8967 9.77769 29.9489 7.893 28.6922 6.25781L23.125 11.8251H31.4485Z"
          fill="#0052B4"
        />
        <path
          d="M0.550781 20.1738C1.10266 22.2212 2.05034 24.1059 3.30709 25.741L8.87422 20.1738H0.550781Z"
          fill="#0052B4"
        />
        <path
          d="M25.7411 3.30716C24.1059 2.05034 22.2213 1.10266 20.1738 0.550781V8.87434L25.7411 3.30716Z"
          fill="#0052B4"
        />
        <path
          d="M6.25781 28.6921C7.893 29.9489 9.77769 30.8966 11.8251 31.4485V23.125L6.25781 28.6921Z"
          fill="#0052B4"
        />
        <path
          d="M11.825 0.550781C9.77762 1.10266 7.89294 2.05034 6.25781 3.30709L11.825 8.87428V0.550781Z"
          fill="#0052B4"
        />
        <path
          d="M20.1738 31.4485C22.2212 30.8966 24.1059 29.9489 25.741 28.6922L20.1738 23.125V31.4485Z"
          fill="#0052B4"
        />
        <path
          d="M23.125 20.1738L28.6922 25.7411C29.9489 24.106 30.8967 22.2212 31.4485 20.1738H23.125Z"
          fill="#0052B4"
        />
        <path
          d="M31.8646 13.9131H18.0871H18.087V0.135438C17.4038 0.0465 16.7073 0 16 0C15.2926 0 14.5962 0.0465 13.9131 0.135438V13.9129V13.913H0.135438C0.0465 14.5962 0 15.2927 0 16C0 16.7074 0.0465 17.4038 0.135438 18.0869H13.9129H13.913V31.8646C14.5962 31.9535 15.2926 32 16 32C16.7073 32 17.4038 31.9536 18.0869 31.8646V18.0871V18.087H31.8646C31.9535 17.4038 32 16.7074 32 16C32 15.2927 31.9535 14.5962 31.8646 13.9131Z"
          fill="#D80027"
        />
        <path
          d="M20.1738 20.1739L27.3136 27.3136C27.642 26.9854 27.9552 26.6422 28.2541 26.2865L22.1415 20.1738H20.1738V20.1739Z"
          fill="#D80027"
        />
        <path
          d="M11.8254 20.1738H11.8252L4.68555 27.3135C5.0138 27.6419 5.35698 27.9551 5.71273 28.254L11.8254 22.1413V20.1738Z"
          fill="#D80027"
        />
        <path
          d="M11.8263 11.8255V11.8254L4.68659 4.68555C4.35822 5.0138 4.04497 5.35698 3.74609 5.71273L9.85878 11.8254H11.8263V11.8255Z"
          fill="#D80027"
        />
        <path
          d="M20.1738 11.8264L27.3136 4.68653C26.9854 4.35816 26.6422 4.04491 26.2865 3.74609L20.1738 9.85878V11.8264Z"
          fill="#D80027"
        />
      </g>
      <defs>
        <clipPath id="clip0_3893_16952">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  SG: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4106_19421)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F0F0F0"
        />
        <path
          d="M0 16.0001C0 7.1635 7.1635 0 16 0C24.8365 0 32 7.1635 32 16.0001"
          fill="#D80027"
        />
        <path
          d="M9.73934 8.34753C9.73934 6.0164 11.3777 4.06903 13.5654 3.59121C13.2292 3.51784 12.8803 3.47803 12.5219 3.47803C9.83247 3.47803 7.65234 5.65815 7.65234 8.34759C7.65234 11.037 9.83247 13.2172 12.5219 13.2172C12.8802 13.2172 13.2291 13.1773 13.5654 13.1039C11.3777 12.6261 9.73934 10.6787 9.73934 8.34753Z"
          fill="#F0F0F0"
        />
        <path
          d="M16 3.82568L16.3454 4.8885H17.4629L16.5587 5.54543L16.9041 6.60825L16 5.95143L15.0959 6.60825L15.4412 5.54543L14.5371 4.8885H15.6546L16 3.82568Z"
          fill="#F0F0F0"
        />
        <path
          d="M13.2892 5.9126L13.6345 6.97547H14.752L13.8479 7.63235L14.1933 8.69516L13.2892 8.03835L12.3849 8.69516L12.7304 7.63235L11.8262 6.97547H12.9437L13.2892 5.9126Z"
          fill="#F0F0F0"
        />
        <path
          d="M18.7109 5.9126L19.0564 6.97547H20.1739L19.2697 7.63235L19.6151 8.69516L18.7109 8.03835L17.8068 8.69516L18.1522 7.63235L17.248 6.97547H18.3655L18.7109 5.9126Z"
          fill="#F0F0F0"
        />
        <path
          d="M17.668 9.04297L18.0133 10.1058H19.1308L18.2267 10.7627L18.5721 11.8255L17.668 11.1687L16.7638 11.8255L17.1092 10.7627L16.2051 10.1058H17.3225L17.668 9.04297Z"
          fill="#F0F0F0"
        />
        <path
          d="M14.3321 9.04297L14.6774 10.1058H15.795L14.8908 10.7627L15.2363 11.8255L14.3321 11.1687L13.4279 11.8255L13.7733 10.7627L12.8691 10.1058H13.9867L14.3321 9.04297Z"
          fill="#F0F0F0"
        />
      </g>
      <defs>
        <clipPath id="clip0_4106_19421">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  NL: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3893_16948)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F0F0F0"
        />
        <path
          d="M16.0009 0C9.12147 0 3.25678 4.342 0.996094 10.4348H31.0058C28.745 4.342 22.8803 0 16.0009 0Z"
          fill="#A2001D"
        />
        <path
          d="M16.0009 31.9993C22.8803 31.9993 28.745 27.6573 31.0057 21.5645H0.996094C3.25678 27.6573 9.12147 31.9993 16.0009 31.9993Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_3893_16948">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  FR: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4106_19417)">
        <path
          d="M16 32C24.8366 32 32 24.8366 32 16C32 7.16344 24.8366 0 16 0C7.16344 0 0 7.16344 0 16C0 24.8366 7.16344 32 16 32Z"
          fill="#F0F0F0"
        />
        <path
          d="M31.9993 15.9999C31.9993 9.12049 27.6573 3.2558 21.5645 0.995117V31.0048C27.6573 28.7441 31.9993 22.8794 31.9993 15.9999Z"
          fill="#D80027"
        />
        <path
          d="M0 15.9999C0 22.8794 4.34206 28.7441 10.4348 31.0047V0.995117C4.34206 3.2558 0 9.12049 0 15.9999Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4106_19417">
          <rect width="32" height="32" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
  AE: (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4106_19417)">
        <path d="m0,0l32.12,0l0,10.64l-32.12,0l0,-10.64z" fill="#00732f"/>
        <path d="m0,10.64l32.12,0l0,10.63l-32.12,0l0,-10.63z" fill="#fff"/>
        <path d="m0,21.27l32.12,0l0,10.64l-32.12,0l0,-10.64z" fill="black"/>
        <path d="m0,0l11.29,0l0,31.91l-11.29,0l0,-31.91z" fill="red"/>
      </g>
      <defs>
        <clipPath id="clip0_4106_19417">
          <circle cx="16" cy="16" r="16" />
        </clipPath>
      </defs>
    </svg>
  ),
  TR:
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fill-rule="evenodd" clipPath="url(#clip0_4106_19417)">
        <path d="m0,0l32,0l0,31.88l-32,0l0,-31.88z" fill="#e30a17" id="svg_2"/>
        <path d="m21.8,16.45c0,4.38 -3.64,7.94 -8.13,7.94s-8.13,-3.57 -8.13,-7.97s3.64,-7.94 8.12,-7.94s8.14,3.57 8.14,7.97l-0.01,0z" fill="#fff" id="svg_3"/>
        <path d="m22.21,16.45c0,3.51 -2.91,6.35 -6.51,6.35s-6.5,-2.84 -6.5,-6.35s2.91,-6.38 6.5,-6.38s6.5,2.84 6.5,6.38l0.01,0z" fill="#e30a17" id="svg_4"/>
        <path d="m23.38,12.72l-0.06,2.94l-2.76,0.73l2.72,0.97l-0.06,2.7l1.77,-2.11l2.68,0.92l-1.55,-2.27l1.89,-2.24l-2.9,0.81l-1.72,-2.46z" fill="#fff" id="svg_5"/>
      </g>
      <defs>
        <clipPath id="clip0_4106_19417">
          <circle cx="16" cy="16" r="16" />
        </clipPath>
      </defs>
  </svg>
};
