import React from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';

type ButtonType = 'primary' | 'secondary' | 'link' | 'text' | 'default';
type ButtonSize = 'large' | 'middle' | 'small' | 'mini';
type ButtonShape = 'default' | 'circle' | 'round';

type ButtonProps = {
  children?: React.ReactNode;
  className?: string;
  contentClassName?: string;
  type?: ButtonType;
  loading?: boolean;
  size?: ButtonSize;
  shape?: ButtonShape;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  icon?: React.ReactNode;
  ghost?: boolean;
  danger?: boolean;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  id?: string;
  onClick?: (e: React.MouseEvent) => void;
};

const Button: React.FC<ButtonProps> = ({
  children,
  className,
  contentClassName,
  loading = false,
  size = 'middle',
  type = 'default',
  shape = 'default',
  prefix,
  suffix,
  icon,
  ghost = false,
  danger = false,
  disabled = false,
  htmlType,
  id = "",
  onClick,
}) => {
  const classNamePrefix = 'button';
  const _className = classNames(
    className,
    styles[classNamePrefix],
    styles[`${classNamePrefix}-type-${type}`],
    styles[`${classNamePrefix}-size-${size}`],
    styles[`${classNamePrefix}-shape-${shape}`],
    {
      [styles[`${classNamePrefix}-loading`]]: loading,
      [styles[`${classNamePrefix}-ghost`]]: ghost,
      [styles[`${classNamePrefix}-danger`]]: danger,
    }
  );

  if (icon) {
    return (
      <button
        type={htmlType}
        title={children?.toString()}
        onClick={onClick}
        id={id}
        className={_className}
      >
        <div className={styles.content}>{icon}</div>
      </button>
    );
  }

  return (
    <button
      type={htmlType}
      disabled={disabled || loading}
      onClick={onClick}
      className={_className}
    >
      {prefix && <div className={styles.prefix}>{prefix}</div>}
      <div className={classNames(contentClassName, styles.content)}>
        {children}
      </div>
      {suffix && <div className={styles.suffix}>{suffix}</div>}
    </button>
  );
};

export { Button };
