import { CountryCode } from 'entities/Country';
import { Server } from 'entities/Server';

const countryServers: Record<CountryCode, Server> = {
  RU: {
    server: 'as-ru.privategatevpn.com',
  },
  KZ: {
    server: 'as1.privategatevpn.com',
  },
  US: {
    server: 'as-us.privategatevpn.com',
  },
  DE: {
    server: 'as-de.privategatevpn.com',
  },
  AE: {
    server: 'uae2.privategatevpn.com'
  },
  TR: {
    server: ""
  }
};

export const getCountryServer = (code: CountryCode): Server => {
  return countryServers[code];
};
