import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { Button, Typography } from 'shared/_ui';
import { getRouteLogin } from 'shared/consts';
import { notify } from 'shared/lib/notification';
import { RegistrationValues, useRegistration } from '../../api/registration';
import { validationScheme } from '../../lib/validationScheme';

import { clearStorages } from 'shared';
import { Flex, MaterialInput } from 'shared/_ui';
import styles from './styles.module.scss';

interface RegistrationFormValues extends RegistrationValues {
  password: string;
  token: string;
}

export const RegistrationForm: React.FC = React.memo(() => {
  const { token } = useParams();
  const navigation = useNavigate();
  const [registration, { isLoading }] = useRegistration();
  const { t } = useTranslation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<RegistrationFormValues>({
    mode: 'onSubmit',
    defaultValues: { token, password: '' },
    resolver: yupResolver(validationScheme),
  });

  const onSubmit = async (data: RegistrationFormValues) => {
    const params: RegistrationValues = {
      token: data.token,
      password: data.password,
      captcha: '',
      lang: localStorage.getItem('i18nextLng') as string,
    };
    await registration(params)
      .unwrap()
      .then(() => {
        notify.success(t('authentication.notify.successRegister'));
        navigation(getRouteLogin());
      })
      .catch((error) => {
        if (error?.status === 400) {
          notify.error(t('shared.error.invalidRequest'));
        } else {
          notify.error(t('shared.error.base'));
        }
      });
  };

  React.useEffect(() => {
    clearStorages(false);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Flex direction="column" spacing={16} className={styles.wrapper}>
        <div className={styles.field}>
          <MaterialInput.Password
            placeholder={t('authentication.password.title')}
            {...register('password')}
          />
          {errors.password && (
            <Typography.Text type="danger">
              {t(
                errors.password?.message
                  ? errors.password?.message
                  : 'shared.error.base'
              )}
            </Typography.Text>
          )}
        </div>
        <Button loading={isLoading} className={styles.button} type="primary">
          {t('authentication.password.createButton')}
        </Button>
      </Flex>
    </form>
  );
});
