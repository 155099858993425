import { $rtkApi } from 'shared/api/rtkApi';

export interface CreateInviteValues {
    email: string;
}

const createInviteApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        createInvite: build.mutation<null, CreateInviteValues>({
            query: (params) => ({
                url: `/api/invite/create`,
                method: 'POST',
                body: params,
            }),
        }),
    }),
    overrideExisting: false,
});

export const useCreateInvite = createInviteApi.useCreateInviteMutation;
