import { $rtkApi } from 'shared/api/rtkApi';

export interface RegistrationValues {
  token: string;
  password: string;
  captcha?: string;
  lang?: string;
}

const registrationApi = $rtkApi.injectEndpoints({
  endpoints: (build) => ({
    registration: build.mutation<null, RegistrationValues>({
      query: (params) => ({
        url: '/api/invite/check',
        method: 'POST',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const useRegistration = registrationApi.useRegistrationMutation;
