import React from 'react';
import styles from './styles.module.scss';
import { Button, Flex, Modal, Typography } from 'shared/_ui';
import { CONTROL_IDS, REF_IDS } from 'shared/consts';
import { daysUntilDate, useStoreUiControls } from 'shared/lib';
import { useTranslation } from 'react-i18next';
import { useRefContext } from 'shared/context';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useFetchUserInfo } from 'entities/Connection';

const PremiumExpirationWarningNineDays: React.FC = () => {
  const { t } = useTranslation();

  const [isMounted, setIsMounted] = React.useState<boolean>(false);

  const { isOpen, close } = useStoreUiControls(
    CONTROL_IDS.PREMIUM_EXPIRATION_BANNER_1
  );

  const { open: openTariffs } = useStoreUiControls(CONTROL_IDS.AVAILABLE_PLANS);
  const { refs } = useRefContext();

  const scrollToTariffList = () => {
    const layoutRef = refs.current[REF_IDS.LAYOUT];
    const availablePlansRef = refs.current[REF_IDS.AVAILABLE_PLANS];

    if (layoutRef && availablePlansRef) {
      const scrollOffset = 32;

      const elementPosition =
        availablePlansRef.getBoundingClientRect().top + layoutRef.scrollTop;
      const offsetPosition = elementPosition - scrollOffset;

      layoutRef.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }
  };
  
  const [isBannerNineShowed, setIsBannerNineShowed] = useLocalStorage<boolean | null>("banner9-state", null);

  const onClick = () => {
    close();
    setIsBannerNineShowed(true);
    openTariffs();
    scrollToTariffList();
  };

  const { data: userInfo } = useFetchUserInfo(null);
  React.useEffect(() => {
    if (!userInfo || !userInfo.paidTo) return;

    const daysLeft = daysUntilDate(userInfo.paidTo);
    if(daysLeft <= 9 && daysLeft > 3) {
      setIsBannerNineShowed(true);
    }

    setIsMounted(isOpen === null || isOpen);
    if(!isBannerNineShowed) {
      setIsMounted(false);
    }
  })


  if (!isMounted) return null;

  return (
    <Modal isOpen={true} onClose={()=>{close();setIsBannerNineShowed(true)}}>
      <Flex
        className={styles.container}
        direction="column"
        spacing={32}
        align="center"
      >
        <Flex direction="column" spacing={24}>
          <Typography.Title level={1}>
            {t('banner.premiumExpiration_9.title')}
          </Typography.Title>
          <Flex direction="column" spacing={8}>
            <Typography.Text size={3}>
              {t('banner.premiumExpiration_9.subtitle')}
            </Typography.Text>
            <Typography.Text type="secondary" size={3}>
              {t('banner.premiumExpiration_9.description')}
            </Typography.Text>
          </Flex>
        </Flex>
        <Button className={styles.button} onClick={onClick} type="primary">
          {t('banner.premiumExpiration_9.button')}
        </Button>
      </Flex>
    </Modal>
  );
};

export { PremiumExpirationWarningNineDays };
