import i18next from 'i18next';
import { $rtkApi } from './rtkApi';

export interface Email {
    email: string;
    languageCode: string
}
export interface OTP {
    email: string;
    otp: number;
}

export interface PlatformToken {
    token: string;
    tokenLifetime: number;
    redirectUrl: string;
}

const otpApi = $rtkApi.injectEndpoints({
    endpoints: (build) => ({
        sendOtp: build.mutation<null, Email>({
            query: (arg: Email) => {
                return {
                    url: `/api/otp`,
                    method: 'POST',
                    body: {
                        email: arg.email,
                        languageCode: i18next.language
                    },
                };
            },
        }),
        otpExchange: build.mutation<PlatformToken, OTP>({
            query: (arg: OTP) => ({
                url: `/api/otp-exchange`,
                method: 'POST',
                body: { 
                    email: arg.email,
                    otp: arg.otp 
                },
            }),
            invalidatesTags: ['UserInfo'],
        }),
    }),
    overrideExisting: false,
});
export const useSendOtp = otpApi.useSendOtpMutation;
export const useOtpExchange = otpApi.useOtpExchangeMutation;